@import './constants/style.module.scss';

body {
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $grey-background-color;
}

.left-align {
  text-align: left !important;
}

.partial-underline {
  position: relative;
  z-index: 0;
}
.partial-underline:before {
  content: '';
  position: absolute;
  z-index: -1;
  width: 80px;
  right: calc(50% - 40px);
  bottom: -15px;
  height: 0;
  border: 3px solid $base-color;
  border-radius: 10px;
}

.container {
  width: 60%;
  margin: 5em auto;
  text-align: center;
}
.container-large {
  width: 100%;
  margin: 2em auto;
  text-align: center;
}
.off-color-section {
  background: $grey-background-color;
  padding: 1em 0;
}

.resource-descriptions {
  min-width: 250px !important;
}

.floating-box {
  background: $off-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 1em 2em;
}

.centered-text {
  text-align: center;
}

.resource-icon {
  width: 40px;
}

.icon-header {
  margin: 1em 0;
}

.no-text-decoration {
  text-decoration: none;

  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
}

@media screen and (min-width: 721px) {
  .icon-header {
    margin-top: 0;
  }

  .container-large {
    width: 80%;
    margin: 5em auto;
  }

  // Columns
  .col {
    display: flex;
    min-width: 250px;
    flex: 1;
    padding: 2em;
  }
  .col-2 {
    width: auto;
    min-width: 375px;
  }
  .col-3 {
    display: flex;
    min-width: 250px;
    max-width: calc(32% - 1em);
    flex: 1 1 calc(32% - 1em);
    padding: 1em;
  }
  .col-4 {
    min-width: 200px;
    flex: 1 1 calc(18% - 0.25em);
    max-width: calc(18% - 0.25em);
    padding: 0.75em;
  }
}

// Possibly temporary Bootstrap login styling:
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

// Modals
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
