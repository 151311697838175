@import './../../../constants/breakpoints.module.scss';

@mixin breakpoint($name) {
  @media screen and (min-width: map-get($breakpoints, $name)) {
    @content;
  }
}

$spacingByBreakpoint: (
  'sm': (
    'xs': 0px,
    'sm': 16px,
    'md': 24px,
    'lg': 32px,
  ),
  'md': (
    'xs': 0px,
    'sm': 16px,
    'md': 24px,
    'lg': 32px,
  ),
  'lg': (
    'xs': 32px,
    'sm': 64px,
    'md': 94px,
    'lg': 120px,
  ),
);

.spacer {
  @each $breakpoint, $spacing in $spacingByBreakpoint {
    @include breakpoint($breakpoint) {
      &--size-xs {
        width: map-get($spacing, 'xs');
        height: map-get($spacing, 'xs');
      }
      &--size-sm {
        width: map-get($spacing, 'sm');
        height: map-get($spacing, 'sm');
      }
      &--size-md {
        width: map-get($spacing, 'md');
        height: map-get($spacing, 'md');
      }
      &--size-lg {
        width: map-get($spacing, 'lg');
        height: map-get($spacing, 'lg');
      }
    }
  }

  &--vertical {
    width: 0; // maybe 100%? not sure..
  }

  &--horizontal {
    height: 0; // maybe 1px?
    display: inline-block;
  }
}
