// To be updated in tandem with ./Colors.ts
$primary: #0e2d52;
$secondary: #ff9015;
$tertiary: #e86826;

$base-color: #0e2d52;
$accent-color: #ff8d0e;
$dark-accent-color: #ac440f;
$grey-background-color: #f9f9f9;
$dark-grey-background-color: #f2f2f2;

$pure-white: #ffffff;
$off-white: #f8f8ff;
$near-black: #111111;
$grey-highlight: #c4c4c4;
$modal-background: #ffa23b;
$active-highlight: #4a617d;
$accent-active-highlight: #4a617d;
$focus-highlight: #479ad5;

$light-text-color: white;
$dark-text-color: black;
$disabled-text-color: #d6d6d6;

$sky-blue: #246e94;

$inks: #000000, #9f9f9f, #dddddd, #ebebeb, #f7f7f7, #ffffff;

$accents: #000000, #9f9f9f, #dddddd;
