@import '../../../constants/style.module.scss';

.button--variant-primary {
  background: $primary;

  svg,
  p {
    color: $light-text-color;
  }

  &:hover {
    background: $pure-white;

    svg,
    p {
      color: $primary;
    }
  }
}

.button--variant-secondary {
  background: $secondary;

  svg,
  p {
    color: $light-text-color;
  }

  &:hover {
    background: $tertiary;
  }
}

.button--variant-tertiary {
  background: $tertiary;

  svg,
  p {
    color: $light-text-color;
  }

  &:hover {
    background: $dark-accent-color;
  }
}

.button--variant-white {
  background: $light-text-color;
  border: 2px solid $dark-text-color;

  svg,
  p {
    color: $dark-text-color;
  }

  &:hover {
    background: $primary;

    svg,
    p {
      color: $light-text-color;
    }
  }
}

.button--variant-text {
  width: fit-content;

  p {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: $dark-text-color;

    color: $dark-text-color;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &:hover {
    p {
      font-weight: 700;
    }
  }
}

.button--display-inline-block {
  display: block;
}

.button--display-inline-block {
  display: inline-block;
  margin: 1em 10px !important;
}

.button--position-center {
  margin: 0 auto;
}

.button--position-right {
  margin-left: auto;
}

.button--position-left {
  margin: 0;
}
