@import '../../constants/style.module.scss';

.typography--variant-h1 {
  font-size: 3.5rem;
}

.typography--variant-h2 {
  font-size: 3rem;
}

.typography--variant-h3 {
  font-size: 2.5rem;
}

.typography--variant-h4 {
  font-size: 2rem;
}

.typography--variant-h5 {
  font-size: 1.5rem;
}

.typography--variant-p {
  font-size: 1rem;
}

.typography--variant-large {
  font-size: 1.25rem;
}

.typography--variant-title {
  font-size: 2.25rem;
  margin: 0.75em 0;
}

.typography--weight-xthin {
  font-weight: 200;
}

.typography--weight-thin {
  font-weight: 300;
}

.typography--weight-regular {
  font-weight: 400;
}

.typography--weight-sbold {
  font-weight: 500;
}

.typography--weight-bold {
  font-weight: 600;
}

.typography--weight-xbold {
  font-weight: 700;
}

.typography--color-light {
  color: $light-text-color;
}

.typography--color-dark {
  color: $dark-text-color;
}

.typography--color-disabled {
  color: $disabled-text-color;
}

.typography--color-primary {
  color: $primary;
}

.typography--color-secondary {
  color: $secondary;
}

.typography--inline {
  display: inline-block;
}

.typography--align-center {
  text-align: center;
}

.typography--align-right {
  text-align: right;
}

.typography--align-left {
  text-align: left;
}
